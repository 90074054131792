import Api from "./Api";
import { isUndefined, isEmpty, cloneDeep }   from "lodash";

export default class PublicPayment {

	static _availablePaymentsStructures = {
		chatLicense: {
			chat_key: null,
			target  : "chatdonate",
		},
		ad         : {
			invoice_key: null,
			target     : "adinvoice",
		},
	};

	static getPayMethods(paymentType, key) {
		if(!isUndefined(this._availablePaymentsStructures[ paymentType ])) {
			const paymentStructure                                 = cloneDeep(this._availablePaymentsStructures[ paymentType ]);
			paymentStructure[ Object.keys(paymentStructure)[ 0 ] ] = key;
			return Api.getPayMethods("tg", paymentStructure);
		}
	}

	static createPayment(paymentType, struct) {
		if(!isUndefined(this._availablePaymentsStructures[ paymentType ])) {
			let payment = {};

			if(paymentType === "chatLicense") {
				/* CHAT LICENSE */
				payment = {
					chat_key  : struct.chat_key,
					target    : "chatdonate",
					tariff    : struct.tariff,
					shop      : struct.shop,
					monthCount: struct.monthCount,
				};

				if(!isEmpty(struct.shopRequiredFieldsData)) {
					payment.params = struct.shopRequiredFieldsData;
				}
			}

			if(paymentType === "ad") {
				/* AD */
				payment = {
					invoice_key: struct.chat_key,
					target     : "adinvoice",
					tariff     : struct.tariff,
					shop       : struct.shop,
					monthCount : struct.monthCount,
				};

				if(!isEmpty(struct.shopRequiredFieldsData)) {
					payment.params = struct.shopRequiredFieldsData;
				}
			}

			return new Promise((resolve, reject) => {
				Api.createPayment("tg", payment)
					 .then(res => {
						 window.location.href = res.data.url;
						 resolve();
					 })
					 .catch(reject);
			});
		}
	}

}
