













































import { Component, Mixins } from 'vue-property-decorator'

import { defaultShopTypeOfSelectedTariff } from 'piramis-base-components/src/components/ServicesCart/logic/helpers'
import { Getaway, SelectedShop, Shop, Tariff } from 'piramis-base-components/src/components/ServicesCart/logic/types'
import { Payment as PaymentData } from 'piramis-base-components/src/components/ServicesCart/logic/types'
import { UseFields } from "piramis-base-components/src/components/Pi"
import MakePayment from 'piramis-base-components/src/components/ServicesCart/components/MakePayment.vue'

import PublicPayment        from "@/includes/logic/PublicPayment";
import { errorNotification } from '@/includes/NotificationService'

enum PaymentTarget {
  ChatLicense = 'chatLicense',
  Ad = 'ad',
}

@Component({
  'components': {
    MakePayment,
  },
})
export default class Payment extends Mixins(UseFields) {
  paymentTypes: Array<PaymentTarget> = [ PaymentTarget.ChatLicense, PaymentTarget.Ad ]

  tariff: Tariff | null = null

  get selectedShop(): SelectedShop | null {
    return defaultShopTypeOfSelectedTariff(this.tariff!.shops as { [key in Shop]: Array<Getaway> })
  }

  async generatedPayment(payment: PaymentData): Promise<void> {
    try {
      this.$baseTemplate.loader.open()
      await PublicPayment.createPayment(this.$route.params.type, {
        chat_key              : this.$route.params.key,
        tariff                : payment.tariff.name,
        shop                  : payment.getaway.code,
        monthCount            : payment.tariff.monthCount,
        shopRequiredFieldsData: payment.requiredFields,
      })
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  async created(): Promise<void> {
    if(!this.paymentTypes.includes(this.$route.params.type as PaymentTarget)) {
      (this as any).$replaceRoute({ name: 'error_404' })
    }

    try {
      this.$baseTemplate.loader.open()
      const { data } = await PublicPayment.getPayMethods(this.$route.params.type, this.$route.params.key)
      this.tariff = data.tariffs[0]
    } catch (error) {
      console.error(error)
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }
}
